<template>
  <b-container class="px-3">
    <h1 class="font-weight-bolder text-center text-colorBlue mt-5">
      {{ this.blog.category_name }}
    </h1>
    <b-row class="mt-2">
      <b-col md="7" class="m-0 p-0 rounded pr-2">
        <h3 class="font-weight-bolder text-colorBlue py-2">
          Blog Introduction
        </h3>
        <p
          class="font-weight-bold text-dark"
          style="text-align: justify; display: block; font-size: 1.2rem"
        >
          {{ this.blog.intro }}
        </p>

        <h3 class="font-weight-bolder text-colorBlue py-2">Blog Description</h3>
        <p
          v-html="blog.description"
          class="font-weight-bold text-dark"
          style="text-align: justify; display: block; font-size: 1.2rem"
        ></p>
      </b-col>
      <b-col md="5">
        <div class="text-center py-2">
          <b-img :src="blog.image" class="img-fluid rounded"></b-img>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    blog: Object,
  },
  mounted() {
    console.log(this.blog, "asdasdas");
  },
  methods: {},
};
</script>

<style scoped>
.my-bg {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-color: rgba(9, 30, 61, 0.8) !important;
  background-blend-mode: multiply;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.button-container .first-button:hover .logo {
  content: url("~@/assets/images/icons/medical-cross-stroke.svg");
}
</style>
